text {
  font-weight: 500;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

div.dagTooltip {
  position:absolute;
  border: 1px solid #333;
  padding: 0.3em 1em;
  background: #fff;
  border-radius: 5px;
  opacity: 0;
}


.DAGControls {
  position: absolute;
  top: 0.3em;
  right: 0.3em;
}

.DAGContainer {
  position: relative;
}
