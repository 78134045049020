.statsCard {
	max-height: 10em;
	color: #555;
}

.statsCard .MuiCardContent-root {
	text-align: center;
}
.statsCard .MuiCardHeader-root {
	background: #eee;
}
