.dvidBanner {
  position:absolute;
  top:0;
  right:0;
}

.aboutPage {
  position: relative;
  padding: 1em;
}
